import React from 'react';
import _ from 'lodash';
import { useStaticQuery, graphql } from "gatsby"

import {Layout} from '../components/index';
import {htmlToReact, Link} from '../utils';
import styled from 'styled-components';

const Container = styled.article`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    margin: 0 20px;
  }
`;

export default function Success(props) {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: {template: {eq: "contact"}}) {
        frontmatter {
          success_title
          success_message
        }
      }
    }
  `)
  console.log(data)

  return (
    <Layout {...props}>
      <div className="outer">
        <div className="inner-medium">
          <Container className="post page post-full">
            <header>
              <h1 className="post-title">
                {data.markdownRemark.frontmatter.success_title}
              </h1>
            </header>
            {data.markdownRemark.frontmatter.success_message && (
              <div className="post-subtitle">
                {data.markdownRemark.frontmatter.success_message}
              </div>
            )}
            <ButtonWrapper>
              <Link to="/podcast" className="button">
                Listen
              </Link>
              <Link to="/blog" className="button">
                Read
              </Link>
            </ButtonWrapper>
          </Container>
        </div>
      </div>
    </Layout>
  );
}
